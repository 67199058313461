<template>
	<div class="card text-dark bg-light">
		<div class="card-header">{{ $t('title') }}</div>
		<form class="card-body login" @submit.prevent="doLogin">
			<div class="login-form-group">
				<input
					class="form-control"
					:class="{ 'is-invalid': v$.email.$error }"
					type="text"
					:placeholder="$t('emailPlaceholder')"
					v-model="email"
				/>
				<div class="invalid-tooltip">{{ v$.email.$errors[0]?.$message }}</div>
			</div>
			<password-input
				v-model:password="password"
				:vError="v$.password.$error"
				:placeholder="$t('passwordPlaceholder')"
				:vErrorMsg="v$.password.$errors[0]?.$message"
				:autocompleteType="password"
			/>
			<div v-show="queryError" class="alert alert-danger">
				{{ queryErrorMessage }}
			</div>
			<router-link :to="{ name: 'ForgotPassword' }">
				{{ $t('forgotPassword') }}
			</router-link>
			<button class="btn btn-primary" type="submit">{{ $t('submitBtn') }}</button>
		</form>
	</div>
</template>

<i18n locale="ru" src="@/locales/ru/components/login.json"></i18n>
<i18n locale="en" src="@/locales/en/components/login.json"></i18n>

<script>
import useVuelidate from '@vuelidate/core';
import PasswordInput from '@/components/inputs/PasswordInput.vue';
import { required, helpers } from '@vuelidate/validators';
import { QueryError } from '@/errors';

export default {
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			email: null,
			password: null,
			passwordVisible: false,
			queryError: null,
		};
	},
	computed: {
		queryErrorMessage() {
			switch (this.queryError) {
				case 'NotFoundError':
					return this.$t('query.error.notFound');
				case 'NotActiveError':
					return this.$t('query.error.notActive');
				case 'MutationTrashedEntityError':
					return this.$t('query.error.MutationTrashedEntityError');
				default:
					return this.$t('query.error.default');
			}
		},
		passwordIcon() {
			return this.passwordVisible ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye';
		},
		passwordType() {
			return this.passwordVisible ? 'text' : 'password';
		},
	},
	validations() {
		return {
			email: {
				required: helpers.withMessage(this.$t('validation.email.required'), required),
			},
			password: {
				required: helpers.withMessage(this.$t('validation.password.required'), required),
			},
		};
	},
	methods: {
		async doLogin() {
			this.v$.$touch();
			if (this.v$.$error) {
				return;
			}
			try {
				this.queryError = null;
				await this.$store.dispatch('auth/login', { email: this.email, password: this.password });
				const redirect = this.$route.query.back;
				if (redirect) {
					if (redirect.startsWith('http')) {
						window.location.href = redirect;
					} else {
						window.location.href = `//${redirect}`;
					}
				} else {
					this.$router.push({ name: 'About' });
				}
			} catch (err) {
				if (err instanceof QueryError) {
					this.queryError = err.cause.type;
				} else {
					this.queryError = true;
				}
			} finally {
				this.v$.$reset();
			}
		},
	},
	components: {
		PasswordInput,
	},
};
</script>

<style scoped lang="scss">
.login {
	display: flex;
	flex-direction: column;

	& > * + * {
		margin-top: 1rem;
	}

	.login-form-group {
		position: relative;
	}

	.alert {
		margin-bottom: 0;
	}
}
</style>
